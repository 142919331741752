import React                            from 'react';
import ReactHtmlParser                  from 'react-html-parser';
import {withSnackbar}                   from 'notistack';
import Grid                             from '@material-ui/core/Grid';
import TextField                        from '@material-ui/core/TextField';
import Button                           from '@material-ui/core/Button';
import Tabs                             from '@material-ui/core/Tabs';
import Tab                              from '@material-ui/core/Tab';
import Paper                            from '@material-ui/core/Paper';
import Typography                       from '@material-ui/core/Typography';
import RadioGroup                       from '@material-ui/core/RadioGroup';
import Radio                            from '@material-ui/core/Radio';
import Select                           from '@material-ui/core/Select';
import MenuItem                         from '@material-ui/core/MenuItem';
import FormControlLabel                 from '@material-ui/core/FormControlLabel';
import FormControl                      from '@material-ui/core/FormControl';
import FormGroup                        from '@material-ui/core/FormGroup';
import Checkbox                         from '@material-ui/core/Checkbox';
import Hidden                           from '@material-ui/core/Hidden';

import Loader                           from 'components/atoms/Loader';
import TopNav                           from 'components/molecules/TopNav';
import MobileNav                        from 'components/molecules/MobileNav';
import authStore, {picProfileInfo}      from 'redux/authStore';
import apis                             from 'common/apis';
import Log                              from 'common/log';
import {errorMsg}                       from 'common/errors';
import utility                          from 'common/utility';
import Skeleton                         from './Skeleton';

const log = Log ('Jobs', 'info');

class Jobs extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav     : false,
			loading       : true,
			isFetched     : false,
			picProfile    : null,
			jobs          : [],
			cart          : [],
			selectedJob   : '',
			btnLoading    : false,
			editable      : false,
			editableData  : {},
			errors        : {},
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.getProfile ();
		this.getCart ();
		this.getJobs ();
	}

	onJobChange = (event, newValue) => {
	
		this.setState ({
			selectedJob : newValue,
			editable    : false,
		});
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	onEdit = () => {
		let __job = this.state.jobs.find (j => j.name === this.state.selectedJob);
		if (!__job) {
			return;
		}

		let questions = {};
		let errors    = {};
		for  (let i = 0; i < __job.custom_fields.length; i++) {
			for (let j = 0; j < __job.custom_fields[i].category_content.length; j++) {
				questions[__job.custom_fields[i].category_content[j].question_name] = 	__job.custom_fields[i].category_content[j].question_ans;
				errors[__job.custom_fields[i].category_content[j].question_name] = false;
			}
		}
		this.setState ({
			editable : true,
			editableData : questions,
			errors       : errors,
		});
	}

	handleChange = (event, question_name) => {
		let __editableData = this.state.editableData;
		let __errors       = this.state.errors;

		__editableData[question_name] = event.target.value;
		__errors[question_name] = false;

		this.setState ({
			editableData : __editableData,
			errors       : __errors,
		});
	}

	handleCustomCheckbox = (event, question_name) => {
		let __editableData = this.state.editableData;
		let checked = event.target.checked;
		let value   = event.target.value;
		let __questionAns = __editableData[question_name];

		if (checked) {
			__questionAns = [...__questionAns, value];
		}
		else {
			__questionAns = __questionAns.filter (item => item !== value);
		}

		this.setState ({
			editableData : {
				...__editableData,
				[question_name] : __questionAns,
			},
		});
	}

	onSave = async () => {
		let __jobs = this.state.jobs;
		let __jobIndex = this.state.jobs.findIndex (j => j.name === this.state.selectedJob);
		if (!__jobIndex < 0) {
			return;
		}

		let __job = __jobs[__jobIndex];
		let __errors = {};

		for  (let i = 0; i < __job.custom_fields.length; i++) {
			for (let j = 0; j < __job.custom_fields[i].category_content.length; j++) {
				if (__job.custom_fields[i].category_content[j]["is_mandatory?"] && !this.state.editableData[__job.custom_fields[i].category_content[j].question_name]) {
					__errors[__job.custom_fields[i].category_content[j].question_name] = true;
				}
			}
		}

		if (Object.keys(__errors).length) {
			this.setState ({
				errors : __errors,
			});
			return;
		}


		let data = {
			job : {
				id : __job.id,
				custom_fields : {
					...this.state.editableData,
				}
			}
		};

		this.setState ({
			btnLoading : true,
		});
		let result;

		try {
			result = await apis.saveJobsDescription (data);
			log.info ({des : result}, 'jobs description save ok');
		}
		catch (err) {
			log.error ({err}, 'err saving job description');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				btnLoading : false,
			});
			return;
		}
		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				btnLoading : false,
			});
			return;
		}

		this.notify (result.message, {variant : 'success'});
		__job = {
			...__job,
			custom_fields : result.custom_fields,
		};

		__jobs[__jobIndex] = __job;

		this.setState ({
			editable : false,
			btnLoading  : false,
			jobs : __jobs,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getJobs = async () => {
		
		let jobs;

		try {
			jobs = await apis.getJobs ();
		}
		catch (err) {
			log.error ({err}, 'error getting jobs');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		log.info ({jobs}, 'jobs get ok');
		let __jobs = Object.keys (jobs).map (j => {
			return {
				name : j,
				...jobs[j]
			};
		});

		this.setState ({
			jobs          : __jobs,
			loading       : false,
			isFetched     : true,
			selectedJob   : __jobs[0] ? __jobs[0].name : "",
		});
	}

	renderEditableFields = (__categoryContent) => {
		
		let inputType = __categoryContent.input_type;
		let error = this.state.errors[__categoryContent.question_name];
		let value = this.state.editableData[__categoryContent.question_name] ? this.state.editableData[__categoryContent.question_name] : "";
		switch (inputType) {
			case 'text' :
				return (
					<TextField
						value     = {ReactHtmlParser(value)}
						variant   = 'outlined'
						error     = {error}
						fullWidth = {true}
						type      = "text"
						onChange  = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'number' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'number'
						fullWidth   = {true}
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}

					/>
				);
			case 'date' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'date'
						onKeyDown   = {this.disableKeyBoard}
						fullWidth   = {true}
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'email' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'email'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'phone_number' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'text'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'url' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'url'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'location' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'text'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'drop_down':
				return (
					<Select
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					>
						{__categoryContent.options.map (item => (
							<MenuItem value = {item} key = {item}> {item} </MenuItem>
						))}
					</Select>
				);
			case 'text_area':
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'text'
						multiline   = {true}
						rows        = {18}
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
						fullWidth
					/>
				);
			case 'currency' :
				return (
					<TextField
						value       = {value}
						error       = {error}
						variant     = 'outlined'
						type        = 'text'
						fullWidth
						onChange    = {(ev) => this.handleChange(ev, __categoryContent.question_name)}
					/>
				);
			case 'check_box' :
				return (
					<FormGroup row = {true}>
						{__categoryContent.options.map ((item, index) => (
							<FormControlLabel
								label   = {item}
								key     = {index}
								control = {
									<Checkbox
										name     = {item}
										value    = {item}
										color    = "primary"
										checked  = {Array.isArray(value) && value.includes(item) ? true : false}
										onChange = {(ev) => this.handleCustomCheckbox(ev, __categoryContent.question_name)}
									/>}
							/>
						))}
					</FormGroup>
				);
			case 'radio_button':
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {value} onChange = {(ev) => this.handleChange(ev, __categoryContent.question_name)} row = {true}>
							{__categoryContent.options.map ((item, index) => (
								<FormControlLabel value = {item}  label = {item} control = {this.renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);
			default :
				return;

		}
	}

	renderJobDescription = () => {

		let __jobs = this.state.jobs;
		let jobDescription = __jobs.find (j => j.name === this.state.selectedJob);
		if (!jobDescription) {
			return;
		}

		jobDescription = jobDescription.custom_fields;

		const descriptionView = [];
		for  (let i = 0; i < jobDescription.length; i++) {
			let __categoryContent = jobDescription[i].category_content;
			let categoryContent = [];
			for (let j = 0; j < __categoryContent.length; j++) {
				if (Array.isArray (__categoryContent[j].question_ans) && !__categoryContent[j].question_ans.length) {
					if (this.state.editable) {
						categoryContent.push (
							<Grid key  = {j} className = 'mt-12'>
								<Typography variant = 'h6'> {__categoryContent[j].question_name}</Typography>
								{!this.state.editable ? <Typography variant = 'body2' style = {{opacity : 0.8, whiteSpace : 'pre-wrap', wordBreak:'break-word', overflowWrap:'break-word'}}> {ReactHtmlParser(__categoryContent[j].question_ans)} </Typography>
									:
									this.renderEditableFields (__categoryContent[j])
								}
							</Grid>
						);
					}
					continue;
				}
				if (!__categoryContent[j].question_ans) {
					if (this.state.editable) {
						categoryContent.push (
							<Grid key  = {j} className = 'mt-12'>
								<Typography variant = 'h6'> {__categoryContent[j].question_name}</Typography>
								{!this.state.editable ? <Typography variant = 'body2' style = {{opacity : 0.8, whiteSpace : 'pre-wrap', wordBreak:'break-word', overflowWrap :'break-word'}}> {ReactHtmlParser(__categoryContent[j].question_ans)} </Typography>
									:
									this.renderEditableFields (__categoryContent[j])
								}
							</Grid>
						);
					}
					continue;
				}
				categoryContent.push (
					<Grid key  = {j} className = 'mt-12'>
						<Typography variant = 'h6'> {__categoryContent[j].question_name}</Typography>
						{!this.state.editable ? <Typography variant = 'body2' style = {{opacity : 0.8, whiteSpace : 'pre-wrap', wordBreak:'break-word', overflowWrap : 'break-word'}}> {ReactHtmlParser(__categoryContent[j].question_ans)} </Typography>
							:
							this.renderEditableFields (__categoryContent[j])
						}
					</Grid>
				);
			}
			if (!categoryContent.length) {
				continue;
			}

			descriptionView.push (
				<Grid key = {i} className = 'mt-16'>
					<Typography variant = 'subtitle1' align = 'center' style = {{backgroundColor : '#5367a0', color : '#fff'}}> {jobDescription[i].category_name} </Typography>
					{categoryContent}
				</Grid>
			);
		}
		return descriptionView;
	};


	renderJobs = () => {
		
		let __jobs = this.state.jobs;

		return (
			<Grid>
				<Paper variant = 'outlined' className = 'p-12 mb-12'>
					<Tabs value = {this.state.selectedJob} textColor = 'inherit' onChange = {this.onJobChange} variant="scrollable" scrollButtons="auto">
						{__jobs.length ? __jobs.map (item => (
							<Tab key = {item.id} value = {item.name} label = {item.name}/>
						)) :
							<Typography variant = 'h6'> No jobs found </Typography>
						}
					</Tabs>
				</Paper>
				<Grid container justify = 'flex-end' className = 'mb-12'>
					{!this.state.editable ?
						<Button variant = 'contained' color = 'primary' onClick = {this.onEdit}>情報を編集する </Button>
						:
						<>
							<Button variant = 'outlined' color = 'primary' onClick = {() => this.setState ({editable : false})}> キャンセル </Button>
							<Button variant = 'contained' color = 'primary' className = 'ml-24' onClick = {this.onSave}>
								{this.state.btnLoading ? <Loader color = 'secondary'/> : '情報を更新する'}
							</Button>
						</>
					}
				</Grid>
			</Grid>
		);
	}

	renderRadio = () => {

		return (
			<Radio color = 'primary' size = 'small'/>
		);
	}

	render () {
	
		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {3}>
						<Grid item lg = {1} xl = {2}>
						</Grid>
						<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12}>
							{this.state.loading ? <Skeleton /> : this.renderJobs ()}
							{this.state.selectedJob ?
								<Paper className = 'p-12'>
									{this.renderJobDescription ()}
								</Paper>
								: null}
						</Grid>
						<Grid item xl = {1} lg = {2}>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Jobs);
