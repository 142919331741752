import React                                                  from 'react';
import {Redirect}                                             from 'react-router-dom';
import Collapse                                               from '@material-ui/core/Collapse';
import {withSnackbar}                                         from 'notistack';
import Grid                                                   from '@material-ui/core/Grid';
import TextField                                              from '@material-ui/core/TextField';
import Divider                                                from '@material-ui/core/Divider';
import Typography                                             from '@material-ui/core/Typography';
import Hidden                                                 from '@material-ui/core/Hidden';
import Tabs                                                   from '@material-ui/core/Tabs';
import Tab                                                    from '@material-ui/core/Tab';
import IconButton                                             from '@material-ui/core/IconButton';
import Fab                                                    from '@material-ui/core/Fab';
import Paper                                                  from '@material-ui/core/Paper';
import Select                                                 from '@material-ui/core/Select';
import MenuItem                                               from '@material-ui/core/MenuItem';
import FormGroup                                              from '@material-ui/core/FormGroup';
import FormControlLabel                                       from '@material-ui/core/FormControlLabel';
import Checkbox                                               from '@material-ui/core/Checkbox';
import ExpandMoreIcon                                         from '@material-ui/icons/ExpandMore';
import ExpandLessIcon                                         from '@material-ui/icons/ExpandLess';
import NavigationIcon                                         from '@material-ui/icons/Navigation';

import TopNav                                                 from 'components/molecules/TopNav';
import MobileNav                                              from 'components/molecules/MobileNav';
import ApplicantCard                                          from 'components/molecules/ApplicantCard';
import Pagination                                             from 'components/molecules/Pagination';
import filterApplicants                                       from 'common/filter';
import searchApplicant                                        from 'common/search';
import apis                                                   from 'common/apis';
import utility                                                from 'common/utility';
import {errorMsg}                                             from 'common/errors';
import Log                                                    from 'common/log';
import path                                                   from 'common/path';
import authStore, {picProfileInfo, selectedJob, isredirected} from 'redux/authStore';
import Skeleton                                               from './Skeleton';

const log = Log ('Landing', 'info');

class Landing extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav         : false,
			applicants        : [],
			loading           : false,
			picProfile        : null,
			cart              : [],
			addLoading        : false,
			jobId             : null,
			filters           : [],
			filterLoading     : true,
			originalApplicant : [],
			message           : '',
			search            : '',
			redirectCheckout  : false,
			alreadyRedirected : false,
			openView          : [],
			showTopButton     : false,
			isBunkei          : props.location.state?.isBunkei || false,
			showDiscarded     : false,
			discardedApplicant : []
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = async () => {

		await this.getProfile();
		this.getCart ();
		this.getFilters ();
		let el = document.getElementById('applicantContainer');
		if (!el) {
			return;
		}
		el.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount = () => {
		let el = document.getElementById('applicantContainer');
		if (!el) {
			return;
		}
		el.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => {
		let el = event.currentTarget;
		const { scrollTop, scrollHeight, clientHeight } = el;
		if (scrollHeight - (scrollTop + clientHeight) < clientHeight && !this.state.showTopButton) {
			this.setState ({
				showTopButton : true,
			});
		}
		if (scrollHeight - (scrollTop+clientHeight) > clientHeight && this.state.showTopButton) {
			this.setState ({
				showTopButton : false,
			});
		}
	}

	handleSelectChange = (event, id) => {
	
		const {value} = event.target;
		let filters = this.state.filters;
		let index = filters.findIndex (f => f.id === id);
		if (!index < 0) {
			return;
		}
		filters[index].value = value;
		let applicants = this.state.originalApplicant;
		if (this.state.showDiscarded) {
			applicants = applicants.filter(app =>
				this.state.discardedApplicant.some(dapp => dapp.applicant_id === app.id && dapp.job_id === this.state.jobId)
			);
		}
		let filteredApplicants = filterApplicants (applicants, filters);
		this.notify (`${filteredApplicants.length} 学生が見つかりました`, {variant : 'info'});
		this.setState ({
			filters,
			applicants : filteredApplicants,
		});
	}

	handleCheckbox = (event, id) => {

		const {value, checked} = event.currentTarget;
		let filters = this.state.filters;
		let index = filters.findIndex (f => f.id === id);
		if (!index < 0) {
			return;
		}
		let filter = filters[index];
		filter.value = checked ? [...filter.value, value] : filter.value.filter (v => v!== value);
		filters[index] = filter;
		let applicants = this.state.originalApplicant;
		if (this.state.showDiscarded) {
			applicants = applicants.filter(app =>
				this.state.discardedApplicant.some(dapp => dapp.applicant_id === app.id && dapp.job_id === this.state.jobId)
			);
		}
		let filteredApplicants = filterApplicants (applicants, filters);
		this.notify (`${filteredApplicants.length} 学生が見つかりました`, {variant : 'info'});
		this.setState ({
			filters,
			applicants : filteredApplicants,
		});
	}

	handleSearch = (event) => {
		
		const {value} = event.currentTarget;
		let applicants = this.state.originalApplicant;
		if (this.state.showDiscarded) {
			applicants = applicants.filter(app =>
				this.state.discardedApplicant.some(dapp => dapp.applicant_id === app.id && dapp.job_id === this.state.jobId)
			);
		}
		let searched = searchApplicant (applicants, value);

		this.setState ({
			applicants : searched,
			search : value,
		});
	}

	handleBunkeiChange = (event) => {
		this.setState ({
			isBunkei : event.target.checked
		});
		this.getApplicants (this.state.jobId, event.target.checked);
	}

	handleDiscarded = async(event) => {
		if (!event.target.checked) {
			this.setState ({
				applicants : this.state.originalApplicant,
				showDiscarded : false,
			});
			return;
		}

		let result;
		this.setState ({
			loading : true,
		});

		try {
			result = await apis.getDiscardedApplicant ();
			log.info ({applicants : result}, 'discarded applicants get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting discarded applicants');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		let discardedApplicant = result.out_of_cart;

		let __applicants = this.state.applicants.filter(app =>
			discardedApplicant.some(dapp => dapp.applicant_id === app.id && dapp.job_id === this.state.jobId)
		);
		this.setState ({
			applicants : __applicants,
			showDiscarded : true,
			discardedApplicant,
			loading : false,
		});

	}

	getApplicants = async (jobId, isBunkei) => {

		this.setState ({
			loading : true,
		});

		let data = {
			applicant : {
				job_id : jobId,
			},
			page : isBunkei ? 'bunkei' : undefined
		};


		let result;

		try {
			result = await apis.getApplicants (data);
			log.info ({applicants : result}, 'get applicants ok');
		}
		catch (err) {
			log.error ({err}, 'error getting applicants');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		if (result.message) {
			this.setState ({
				message : result.message,
				loading : false,
			});
			return;
		}

		let __applicants = utility.sort (result, 'recommended', 1);
	
		this.setState ({
			message    : '',
			loading    : false,
			applicants : __applicants,
			originalApplicant : __applicants, //saving this to filter applicant from orginal data
		});
		return result;
	}

	getFilters = async () => {
	
		let result;
		try {
			result = await apis.getFilters ();
		}
		catch (err) {
			log.error ({err}, 'error getting filters');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				filterLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		log.info ({filters : result}, 'get filters ok');
		let __filters = [];
		let __openView = [];
		let customFields = result.custom_fields;
		for (let i = 0; i < customFields.length; i++) {
			__filters.push ({
				...customFields[i],
				value : customFields[i].question_type === 'check_box' ? [] : 0
			});
			__openView.push (false);
		}

		this.setState ({
			filters : __filters,
			filterLoading : false,
			openView : __openView,
		});
	}

	addToCart = async (applicantId) => {
		
		let data = {
			applicant_id   : applicantId,
			company_pic_id : this.state.picProfile.id,
			job_id         : this.state.jobId,
		};

		let result;
		try {
			result = await apis.addToCart (data);
		}
		catch (err) {
			log.error ({err}, 'error adding to cart');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('学生の追加ができません、もう一度試してください。', {variant : 'error'});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		log.info ({result}, 'add to cart ok');
		let _cart = this.state.cart;
		_cart.push (data);

		this.setState ({
			cart : _cart,
		});
		this.notify (result.message, {variant : 'success'});
	}

	removeFromCart = async (applicantId, jobId) => {
		
		let data = {
			applicant_id   : applicantId,
			company_pic_id : this.state.picProfile.id,
			job_id         : jobId,
		};

		let result;
		try {
			result = await apis.removeFromCart (data);
		}
		catch (err) {
			log.error ({err}, 'error adding to cart');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('選出した学生を削除できません、もう一度試してください。', {variant : 'error'});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		log.info ({result}, 'removed to cart ok');
		let _cart = this.state.cart;

		let __cart = [];

		for (let i = 0; i < _cart.length; i++) {
			if (_cart[i].applicant_id === data.applicant_id && _cart[i].job_id === data.job_id) {
				continue;
			}
			__cart.push (_cart[i]);
		}

		this.setState ({
			cart : __cart,
		});
		this.notify (result.message, {variant : 'success'});
	}

	getCart = async () => {
	
		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		let authInfo = authStore.getState ();
		let __redirect = authInfo.redirectCheckout;
		if (result.cart.length) {
			authStore.dispatch(isredirected(true));
		}
		this.setState ({
			cart : result.cart,
			redirectCheckout : !__redirect && result.cart.length ? true : false,
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile || !picProfile.jobs) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}

			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : null; //also fetch applicants if jobId is null

			this.setState ({
				picProfile,
				jobId,
			});
			this.getApplicants (jobId, this.state.isBunkei);
			authStore.dispatch (selectedJob (jobId));
			authStore.dispatch (picProfileInfo (picProfile));
			return;
		}
		let jobId = authInfo.jobId;

		this.setState ({
			picProfile,
			jobId,
		});

		this.getApplicants (jobId, this.state.isBunkei);
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	handleFilterCollapse = (index) => {
		let __openView = this.state.openView;
		__openView[index] = !__openView[index];
		this.setState ({
			openView : __openView,
		});
	}

	onJobChange = async (event, newValue) => {

		let jobId = newValue;
		this.setState ({
			jobId,
		});
		authStore.dispatch (selectedJob (jobId));
		let applicants = await this.getApplicants (jobId, this.state.isBunkei);

		if (this.state.showDiscarded) {
			applicants = applicants.filter(app =>
				this.state.discardedApplicant.some(dapp => dapp.applicant_id === app.id && dapp.job_id === newValue)
			);
		}


		let filteredApplicants = filterApplicants (applicants, this.state.filters);
		if (!this.state.search || this.state.search.trim() === "") {
			this.setState ({
				applicants : filteredApplicants,
			});
			return;
		}
		let searched = searchApplicant (filteredApplicants, this.state.search);


		this.setState ({
			applicants : searched,
		});
	}

	renderValue = (id, type, options, value, index) => {
	
		switch (type) {
			case 'drop_down' :
				return(
					<Select variant = 'outlined' value = {value} fullWidth onChange = {(ev) => this.handleSelectChange(ev, id)}>
						<MenuItem value = {0}> なし </MenuItem>
						{options.map (item => (
							<MenuItem value = {item.name} key = {item.id}> {item.name} </MenuItem>
						))}
					</Select>
				);
			case 'check_box' :
				return (
					<FormGroup row = {true} style = {index === 0 ? {display : 'grid'} : null}>
						{options.map ((item, index) => (
							<FormControlLabel
								label   = {item.name}
								key     = {index}
								control = {<Checkbox color = 'primary' checked = {value.includes(item.name) ? true : false} value = {item.name} onChange = {(ev) => this.handleCheckbox(ev, id)}/>}/>
						))}
					</FormGroup>
				);
			default :
				return;
		}
	}

	renderFilters = () => {
	
		if (this.state.filterLoading) {
			return (
				<Paper className = 'p-12'>
					<Skeleton type = 'filter'/>
				</Paper>
			);
		}

		let filters = this.state.filters;

		if (!filters.length) {
			return (
				<Typography variant = 'h5' align = 'center'> フィルターはかけていません。</Typography>
			);
		}

		let contents = [];

		contents.push (
			<Grid key = 'search' className = 'mb-12'>
				<Typography variant = 'subtitle2'> 検索 </Typography>
				<TextField
					placeholder = 'ID'
					value       = {this.state.search}
					onChange    = {this.handleSearch}
					variant     = 'outlined'
					InputProps  = {{
						style : {
							height : 44
						}
					}}
					fullWidth
				/>
			</Grid>
		);

		/**To handle bunkei */
		contents.push (
			<FormGroup row = {true} className='mt-4 mb-4'>
				<FormControlLabel
					label    = '文系学生'
					className='bunkei-label'
					control  = {<Checkbox color = 'primary' checked = { this.state.isBunkei } onChange = { this.handleBunkeiChange }/>}
				/>
				<FormControlLabel
					label    = '検討リストから削除済み学生'
					className='bunkei-label'
					control  = {<Checkbox disabled = {this.state.loading} color = 'primary' checked = { this.state.showDiscarded } onChange = { this.handleDiscarded }/>}
				/>
			</FormGroup>
		);

		for  (let i = 0; i < filters.length; i++) {
			contents.push (
				<Grid key = {i}>
					<Typography variant = 'subtitle2'> {filters[i].name}
						<Hidden only = {['md', 'lg', 'xl']}>
							<IconButton onClick = {() => this.handleFilterCollapse(i)}> {this.state.openView[i] === false ? <ExpandMoreIcon/> : <ExpandLessIcon/>}</IconButton>
						</Hidden>
					</Typography>
					<Hidden only = {['md', 'lg', 'xl']}>
						<Collapse in = {this.state.openView[i]} timeout = "auto" unmountOnExit>
							{this.renderValue (filters[i].id, filters[i].question_type, filters[i].options, filters[i].value, i)}
						</Collapse>
					</Hidden>
					<Hidden only = {['xs', 'sm']}>
						{this.renderValue (filters[i].id, filters[i].question_type, filters[i].options, filters[i].value, i) || ''}
					</Hidden>
					<Divider className = 'mt-12 mb-8'/>
				</Grid>
			);
		}
		return contents;
	}

	renderApplicants = () => {
	
		if (this.state.message) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> {this.state.message}</Typography>
			);
		}

		if (!this.state.originalApplicant.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 職種をお選びください </Typography>
			);
		}

		if (!this.state.applicants.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 検索結果に一致する学生はありませんでした。 </Typography>
			);
		}

		let applicants = this.state.applicants.map (items => (
			<ApplicantCard
				page           = 'LANDING'
				applicant      = {items}
				key            = {items.id}
				cart           = {this.state.cart}
				jobId          = {this.state.jobId}
				addToCart      = {this.addToCart}
				removeFromCart = {this.removeFromCart}
			/>
		)).reverse();
		
		return (
			<Pagination data = {applicants} />
		);
	}

	render () {
		
		if (this.state.redirectCheckout) {
			return (
				<Redirect to = {path.checkout} />
			);
		}

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid id = 'applicantContainer' className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					{!this.state.picProfile ? <div></div> :
						<Grid container spacing = {3}>
							<Grid item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
								<Paper className = 'p-12' variant = 'outlined'>
									{this.renderFilters ()}
								</Paper>
							</Grid>
							<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9}>
								<Paper className = 'p-12' variant = 'outlined'>
									<Tabs value = {this.state.jobId} textColor = 'inherit' onChange = {this.onJobChange} variant="scrollable" scrollButtons="auto">
										{this.state.picProfile.jobs.length ? this.state.picProfile.jobs.map (item => (
											<Tab key = {item.job_id} value = {item.job_id} label = {item.job_title}/>
										)) :
											<Tab key = {'0'} value = {null} label = {'All'}/>
										}
									</Tabs>
								</Paper>
								{this.state.loading ? <Skeleton /> : this.renderApplicants ()}
							</Grid>
						</Grid>
					}
					{this.state.showTopButton ?  <Fab size = 'small' style = {{position : 'fixed', bottom : '5%', right : '2%'}} onClick = {() => utility.scrollToTop('applicantContainer')}>
						<NavigationIcon />
					</Fab> : null}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Landing);
