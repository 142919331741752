import xhr   from './xhr';
import vapis from './vapis';

const apis = {};
const api_prefix = '/api_company/v1';

/* <---------Login Apis---------->*/

apis.login = async (data) => {
	let result  = await xhr.post (`${api_prefix}/auth/login.json`, data);
	return result;
};

apis.logout = async () => {
	let result = await xhr.post (`${api_prefix}/company_pics/logout.json`);
	return result;
};

apis.resetPassword = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/reset_password_logged_in.json`, data);
	return result;
};

apis.initialResetPassword = async (data) => {
	let result = await xhr.post (`${api_prefix}/reset_password.json`, data);
	return result;
};

/* <---------Landing Apis---------->*/

apis.getApplicants = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/get_applicants.json`, data);
	return result;
};

apis.getSidebarApplicants = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/get_sidebar_applicants.json`, data);
	return result;
};

apis.getBulkApplicants = async(data) => {
	let result = await xhr.post (`${api_prefix}/companies/get_applicants.json`, data);
	return result;
};

apis.getFilters = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/get_filters.json`);
	return result;
};

/* <---------Cart Apis---------->*/


apis.getCart = async () => {
	let result = await xhr.get (`${api_prefix}/shortlisted_applicants/get_cart.json`);
	return result;
};

apis.addToCart = async (data) => {
	let result = await xhr.post (`${api_prefix}/shortlisted_applicants/add_to_cart.json`, data);
	return result;
};

apis.removeFromCart = async (data) => {
	let result = await xhr.post (`${api_prefix}/shortlisted_applicants/remove_from_cart.json`, data);
	return result;
};

apis.emptyCart = async (data) => {
	let result = await xhr.post (`${api_prefix}/shortlisted_applicants/empty_cart.json`, data);
	return result;
};

apis.checkout = async (data) => {
	let result = await xhr.post(`${api_prefix}/shortlisted_applicants/checkout.json`, data);
	return result;
};

apis.rateApplicant = async (data) => {
	let result = await xhr.post (`${api_prefix}/shortlisted_applicants/rate_applicant.json`, data);
	return result;
};

apis.getDiscardedApplicant = async () => {
	let result = await xhr.get (`${api_prefix}/shortlisted_applicants/discarded_applicants.json`);
	return result;
};

/* <---------Profile Apis---------->*/

apis.getProfile = async () => {
	let result = await xhr.get (`${api_prefix}/company_pics/my_profile.json`);
	return result;
};

apis.editProfile = async () => {
	let result = await xhr.get (`${api_prefix}/company_pics/edit_profile.json`);
	return result;
};

apis.getDownloadableDoc = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/get_downloadable_documents_list.json`, data);
	return result;
};

apis.getDownloadLink = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/get_download_url_for_document.json`, data);
	return result;
};

apis.getBulkApplicants = async(data) => {
	let result = await xhr.post (`${api_prefix}/companies/get_applicants.json`, data);
	return result;
};
				
/* <---------Applicant's Apis---------->*/

apis.getApplicantProfile = async (data) => {
	let result =  await xhr.post (`${api_prefix}/applicants/applicant_profile.json`, data);
	return result;
};

apis.getInterviewProfile = async (data) => {
	let result =  await xhr.post (`${api_prefix}/applicants/applicant_profile_for_interviews.json`, data);
	return result;
};

apis.getVideoThumbnail = async (vurl) => {
	let result = await vapis.get (`/videos/${vurl}/pictures`);
	return result;
};

apis.getApplicantCount = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/get_applicants_count.json`);
	return result;
};

/* <---------Interview Apis---------->*/

apis.orderHistory = async () => {
	let result = await xhr.get (`${api_prefix}/shortlisted_applicants/get_order_history.json`);
	return result;
};

apis.interviewScheduled = async () => {
	let result = await xhr.get (`${api_prefix}/interviews/get_interview_schedule.json`);
	return result;
};

apis.interviewHistory = async () => {
	let result = await xhr.get (`${api_prefix}/interviews/get_interview_history.json`);
	return result;
};

apis.submitRating = async (data) => {
	let result = await xhr.post (`${api_prefix}/interviews/rate_interview.json`, data);
	return result;
};

/* <---------Forgot Apis---------->*/

apis.forgotPassword = async (data) => {
	let result = await xhr.post (`${api_prefix}/request_password_reset_email.json`, data);
	return result;
};

apis.sendPicEmail =  async (data) => {
	let result = await xhr.post (`${api_prefix}/company_pics/send_welcome_email_on_password_set.json`, data);
	return result;
};

/* <---------Jobs Apis---------->*/

apis.getJobs = async () => {
	let result = await xhr.get (`${api_prefix}/jobs/get_jobs.json`);
	return result;
};

apis.saveJobsDescription = async (data) => {
	let result = await xhr.put (`${api_prefix}/jobs/${data.job.id}.json`, data);
	return result;
};

/* <---------Japanese class Apis---------->*/

apis.getApplicantsList = async () => {
	let result = await xhr.get (`${api_prefix}/jclass_pdf_histories/get_applicants_list.json`);
	return result;
};

apis.jclassPdfDownloadLink = async (data) => {
	let result = await xhr.post (`${api_prefix}/jclass_pdf_histories/get_download_link`, data);
	return result;
};

apis.getApplicantPhoto = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/get_applicants_photograph.json`, data);
	return result;
};

/* <---------onboarding Apis---------->*/

apis.getOnboardedApplicant = async() => {
	let result = await xhr.get (`${api_prefix}/applicants/get_onboarded_applicants.json`);
	return result;
};

apis.getOnboardingEditProfile = async (applicantId) => {
	let data = {
		applicant : {
			id: applicantId
		}
	};
	let result = await xhr.post (`${api_prefix}/applicants/edit_profile.json`, data);
	return result;
};

apis.updateOnboardingApplicants = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/update.json`, data);
	return result;
};

apis.getOnboardingCompanyProfile = async () => {
	let result = await xhr.get (`${api_prefix}/companies/company_profile.json`);
	return result;
};

apis.getOnboardingCompanyEditProfile = async () => {
	let result = await xhr.get (`${api_prefix}/companies/edit_company_profile.json`);
	return result;
};

apis.updateOnboardingCompanies = async (data) => {
	let result = await xhr.put (`${api_prefix}/companies/update.json`, data);
	return result;
};

apis.saveProfile = async (data) => {
	let result = await xhr.putform (`${api_prefix}/applicants/update.json`, data);
	return result;
};

export default apis;
