import path from 'common/path';
import moment from 'moment';

const utility = {};

utility.validateEmail = (email) => {

	//eslint-disable-next-line
	let reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	if (!reg.test (email)) {
		return false;
	}
	return true;
};

utility.validateTextField = (value) => {
	
	if (!value || value.trim () === '') {
		return false;
	}
	return true;
};

utility.validateNumber = (value) => {
	
	if (!value) {
		return false;
	}

	return true;
};

utility.validateCurrency = (value) => {

	let reg = /¥[^@\s]*$/;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.validatePhoneNumber = (value) => {

	let reg = /^[0-9]{8,12}$/;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.validateUrl = (value) => {

	let reg = /^(https?):\/\/[^\s$.?#].[^\s]*$/i;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.getNameInitials = (name) => {
	
	if (!name) {
		return;
	}

	let _initials = name.split (" ");
	let initials;
	if (!_initials[1]) {
		initials = `${_initials[0][0]}${_initials[0][1]}`.toUpperCase ();
		return initials;
	}
	initials =  `${_initials[0][0]}${_initials[1][0]}`.toUpperCase ();
	return initials;
};

utility.getRandomNumber = (min, max) => {
	
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min);
};

utility.sortData = (array, key, order) => {
 
	if (key === 'date') {
		return (array.sort ((item1, item2) => compareTime (item1, item2, order)));
	}
 
	function compareTime (item1, item2, order) {
 
		let item1Date = new Date (item1.date);
		let item2Date = new Date (item2.date);
 
		if(item1Date < item2Date){
			return order;
		}
		else if(item1Date > item2Date){
			return order = 0 - order;
		}
		else{
			return 0;
		}
	}
};

utility.sort = (array, key, order) => {
 
	return (array.sort ((item1, item2) => compareTime (item1, item2, order)));
 
	function compareTime (item1, item2, order) {
 
		let item1Data = item1[key];
		let item2Data = item2[key];
 
		if(item1Data < item2Data){
			return order;
		}
		else if(item1Data > item2Data){
			return order = 0 - order;
		}
		else{
			return 0;
		}
	}
};

utility.autoSelectOptions = (array, name, value) => {
		
	if (!Array.isArray (array)) {
		return;
	}

	let options = array.map (item => {
		return {
			label : item[name],
			value : item[value],
		};
	});
	return options;
};

utility.redirectLogin = () => {
	setTimeout (()=> {window.location.pathname = path.login;}, 3000);
};

utility.scrollToTop = (el) => {
	let topDiv = document.getElementById(el || 'scroll-top');
	if (!topDiv) {
		return;
	}
	topDiv.scrollTo({top: 0, behavior:'smooth'});
};

utility.getImgSrc = (imgInfo) => {
	if (!imgInfo) {
		return;
	}
	return `data:image/*;base64,${imgInfo}`;
};

utility.convertToCSV = (data) => {
	const headers = Object.keys(data[0]).join(",") + "\n";
	const rows = data.map(row =>
		Object.values(row).map(value => {
			// Ensure the value is a string and replace newlines with spaces
			const stringValue = String(value).replace(/\n/g, ' ').replace(/\r/g, ' ');

			// If value contains commas or double quotes, escape it with double quotes
			if (stringValue.includes(",") || stringValue.includes('"')) {
				return `"${stringValue.replace(/"/g, '""')}"`; // Escape double quotes
			}
			return stringValue;
		}).join(",")
	).join("\n");

	return headers + rows;
};

utility.downloadCSV = (csvContent, fileName = "data.csv") => {
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement("a");
	const url = URL.createObjectURL(blob);

	link.setAttribute("href", url);
	link.setAttribute("download", fileName);
	link.style.visibility = "hidden";

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

utility.downloadApplicants = (applicants, page) => {
	if (!Array.isArray(applicants) || !applicants.length) {
		return;
	}

	let __appData = applicants.map(app => {
		let customFields = app.custom_fields || {};
		let masterUni = app.universities?.find(uni => uni.education_background === 'Master');
		let bachelorUni = app.universities?.find(uni => uni.education_background === 'Bachelor');
		let __uni = masterUni || bachelorUni || {};

		return {
			...(page === 'INTERVIEW' && {
				'氏名' : app.name || app.applicant_name || ' '
			}),
			...(page === 'CHECKOUT' && {
				'JOB' : app.job_name || ' '
			}),
			'呼び名' : customFields['呼び名'] || ' ',
			'FO ID' : app.id || ' ',
			'国籍' : app.country || ' ',
			'大学': __uni.university_name || ' ',
			'学歴' : __uni.education_background || ' ',
			'学部': __uni.faculty || ' ',
			'学科' : __uni.department || ' ',
			'ASIA to JAPANコメント' : customFields['ASIA to JAPAN コメント'] || ' ',
			'日本語力' : Array.isArray(customFields['日本語力']) && customFields['日本語力'].length ? customFields['日本語力'].join(' | ') : ' ',
			'英語力': Array.isArray(customFields['英語力']) && customFields['英語力'].length ? customFields['英語力'].join(' | ') : ' ',
			'入社タイミング' : Array.isArray(customFields['入社タイミング']) && customFields['入社タイミング'].length ? customFields['入社タイミング'].join(' | ') : ' ',
			'国内在住' : Array.isArray(customFields['国内在住']) && customFields['国内在住'].length ? customFields['国内在住'].join(' | ') : ' ',
			'年齢' : app.age || ' ',
			'性別' : customFields['性別'] || ' ',
			'卒業年' : customFields['卒業年'] || ' ',
			'卒業月' : customFields['卒業月'] || ' ',
			'JLPT': customFields['JLPT'] || ' ',
			'IT Skills': Array.isArray(customFields['IT Skills']) && customFields['IT Skills'].length ? customFields['IT Skills'].join(' | ') : ' ',
			'Other IT Skills': Array.isArray(customFields['other IT Skills']) && customFields['other IT Skills'].length ? customFields['other IT Skills'].join(' | ') : ' ',
		};
	});

	let csvContent = utility.convertToCSV(__appData);
	utility.downloadCSV(csvContent, 'applicant-data.csv');
};

utility.downloadInterviews = (scheduled) => {
	let csvData = [];
	for (const sch of scheduled) {
		let __interviews = sch.interviews;
		if (!__interviews || !__interviews.length) {
			continue;
		}
		for (const __intv of __interviews) {
			for (const reviews of __intv.interview_reviews) {
				let data = {
					"学生ID" : sch.id,
					"学生氏名" : sch.name,
					"評価した面接官" : reviews.pic_name || ' ',
					"面接日時" : __intv.start_time ? moment(__intv.start_time).format('ll HH:mm') : ' ',
					"JOB 名" : __intv.job_name || ' ',
					"結果" : reviews.result || ' ',
					"日本語力" : reviews.japanese_level || ' ',
					"スキルマッチ" : reviews.skill_match || ' ',
					"人物マッチ" : reviews.personality_match || ' ',
					"コメント": reviews.note || ' ',
					"選考ステップ" : __intv.step,
					"最終評価": reviews.is_final ? "はい"  : "いいえ"
				};
				csvData.push (data);
			}
		}
	}
	let csvContent = utility.convertToCSV(csvData);
	utility.downloadCSV(csvContent, 'interview-reviews.csv');
};

export default utility;
