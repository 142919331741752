import React              from 'react';
import {Link as Navlink}  from 'react-router-dom';
import moment             from 'moment';
import swal               from 'sweetalert';
import Paper              from '@material-ui/core/Paper';
import Alert              from '@material-ui/lab/Alert';
import MuiLink            from '@material-ui/core/Link';
import Divider            from '@material-ui/core/Divider';
import Typography         from '@material-ui/core/Typography';
import Grid               from '@material-ui/core/Grid';
import Button             from '@material-ui/core/Button';
import Rating             from '@material-ui/lab/Rating';
import StarOutlineIcon    from '@material-ui/icons/StarBorder';
import { Collapse }       from '@material-ui/core';
import {
	User,
	World,
	DeviceDesktop,
	Notebook,
	Disc
}                         from 'tabler-icons-react';

import path               from 'common/path';
import Loader             from 'components/atoms/Loader';
import {int_result}       from 'common/config';
import authStore          from 'redux/authStore';
import {styles}           from './style';

function ApplicantCard (props) {

	const {applicant, page, cartIndex} = props;
	const [loading, setLoading] = React.useState (false);
	const [viewMore, setViewMore] = React.useState (false);

	function renderCustomFields () {

		if (page === 'LANDING' || page === 'CHECKOUT' || page === 'JCLASS') {
			return;
		}

		if (!applicant.custom_fields) {
			return;
		}
		let customFields = [];
		let _custom_fields = applicant.custom_fields;
		let __custom_keys = Object.keys (_custom_fields);
		for (let i = 0; i < __custom_keys.length; i++) {
			if (Array.isArray (_custom_fields[__custom_keys[i]]) && !_custom_fields[__custom_keys[i]].length) {
				continue;
			}
			if (Array.isArray (_custom_fields[__custom_keys[i]])) {
				customFields.push (
					<Typography variant = 'body2' key = {i}> <strong>{__custom_keys[i]} : </strong> {_custom_fields[__custom_keys[i]].join (', ')} </Typography>
				);
				continue;
			}
			if (_custom_fields[__custom_keys[i]]) {
				customFields.push (
					<Typography variant = 'body2' key = {i}> <strong>{__custom_keys[i]} : </strong> {_custom_fields[__custom_keys[i]]} </Typography>
				);
			}
		}
		return customFields;
	}

	async function removeFromCart (applicantId, jobId) {
		
		setLoading (true);
		await props.removeFromCart (applicantId, jobId);
		setLoading (false);
	}

	async function addToCart () {
	
		setLoading (true);
		await props.addToCart (applicant.id);
		setLoading (false);
	}

	function handleFinalRatingModalOpen(scheduledId, applicantId, hasTempReviews) {
		if (!hasTempReviews) {
			swal({
				text: '一次評価を入力していないようです。本当に最終評価をしますか？',
				icon: 'warning',
				buttons: true,
			}).then (async(onOk) => {
				if (onOk) {
					props.openRatingModal(scheduledId, applicantId, true);
				}
			});
			return;
		}
		props.openRatingModal(scheduledId, applicantId, true);
	}

	function onSubmitStar (event, newValue) {
		props.onSubmitStar (newValue, applicant.id, applicant.job_id);
	}

	function renderStars () {
		return (
			<Grid style =  {{textAlign : 'center', border : props.ratingError[cartIndex] ? '1px solid #C33934' : 'none', borderRadius : '4px'}}>
				<Typography variant = 'subtitle2' className = 'mb-4' style = {{color : '#7b7b7b'}}> ニーズ合致度  <span style = {{color : '#C33934'}}>*</span> </Typography>
				<Rating name = {`${applicant.id}-${applicant.job_id}`} value = {applicant.rating} onChange={onSubmitStar}/>
			</Grid>
		);
	}

	function renderReviews(scheduled, i, reviews, reviewIndex, isLastReview, hasTempReviews, isFinalDone) {
		let authInfo = authStore.getState();
		let picProfile = authInfo.picProfile;
		if (!reviews) {
			reviews = {};
		}
		return (
			<Grid container spacing={2} className='mt-24' alignItems='center' key = {reviewIndex + i}>
				<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
					{reviewIndex === 0 &&
						<>
							<Typography variant='h5' style={{ color: '#278bfa' }} className='mb-16'> {scheduled[i].step}</Typography>
							<Typography variant='body2' style={{ color: '#278bfa' }}> {scheduled[i].start_time ? moment(scheduled[i].start_time).format('ll') : '_'} </Typography>
							<Typography variant='subtitle1' style={{ fontSize: '24px' }}> {scheduled[i].start_time ? moment(scheduled[i].start_time).format('HH:mm') : '_'} </Typography>
						</>
					}
				</Grid>
				<Grid container spacing={2} item xs={8} sm={8} md={8} lg={8} xl={8} style={{ borderBottom: '1px solid #ddd' }}>
					{reviews.pic_name &&
						<Grid item sm={12}>
							<Typography align='center' variant= {reviews.is_final ? 'subtitle2' : 'subtitle1'} color='error'> {reviews.is_final ? `最終評価 | ${reviews.pic_name}` : reviews.pic_name} </Typography>
						</Grid>
					}
					<Grid item xs={3} m={3} md={3} lg={3} xl={3}>
						<Typography variant='body2' color='primary'> 日本語力 </Typography>
						<Typography variant='subtitle1' style={{ fontSize: '18px' }}> {reviews.japanese_level || 'N/A'} </Typography>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
						<Typography variant='body2' color='primary'> スキルマッチ </Typography>
						<Typography variant='subtitle1' style={{ fontSize: '18px' }}> {reviews.skill_match || 'N/A'} </Typography>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
						<Typography variant='body2' color='primary'> 人物マッチ</Typography>
						<Typography variant='subtitle1' style={{ fontSize: '18px' }}> {reviews.personality_match || 'N/A'} </Typography>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
						<Typography variant='body2' color='primary'> 結果 </Typography>
						<Typography variant='subtitle1' style={{ fontSize: '18px' }}> {int_result[reviews.result] ? int_result[reviews.result] : reviews.result || 'N/A'} </Typography>
					</Grid>
					<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
						<Typography variant='body2' color='primary'> コメント </Typography>
						<Typography variant='subtitle1' style={{ fontSize: '14px', maxHeight: '150px', overflow: 'auto' }}> {reviews.note || 'N/A'} </Typography>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
						<Typography variant='body2' color='primary'> タイプ </Typography>
						<Typography variant='subtitle1' style={{ fontSize: '14px', maxHeight: '150px', overflow: 'auto' }}> {scheduled[i].interview_type || 'N/A'} </Typography>
					</Grid>
					<Grid>
						{applicant.interview_url ?
							<Typography variant="body2"><strong>面接URL:</strong>
								<MuiLink href={applicant.interview_url} target="_blank"> {applicant.interview_url} </MuiLink>
							</Typography>
							: null}
					</Grid>
					{reviewIndex === 0 && !viewMore &&
						<Grid item sm = {12} container justify='center'>
							<Button onClick = {() => setViewMore(!viewMore)} color='primary'> 他の評価を見る </Button>
						</Grid>
					}
					{isLastReview && viewMore &&
						<Grid item sm = {12} container justify='center'>
							<Button onClick = {() => setViewMore(!viewMore)} color='primary'> 折りたたむ </Button>
						</Grid>
					}
				</Grid>
				<Grid item xs={2} sm={2} md={2} lg={2} xl={2} container justify='flex-end'>
					{reviewIndex === 0 &&
						<Grid>
							<Button
								variant='outlined'
								color='primary'
								onClick={() => props.openRatingModal(scheduled[i].id, applicant.id, false)}
								disabled = {hasTempReviews || isFinalDone}
							>
								{hasTempReviews || isFinalDone ? '評価を入力済み' : '一次評価を入力'}
							</Button>
							{!hasTempReviews && !isFinalDone &&
								<Typography variant='body1' style={{fontSize : 12}} color='primary'> {picProfile.name}様の評価を入力ください</Typography>
							}
							<Button
								variant='contained'
								color='primary'
								onClick={() => handleFinalRatingModalOpen(scheduled[i].id, applicant.id, hasTempReviews)}
								disabled = {isFinalDone}
								className='mt-16'
							>
								{isFinalDone ? '最終評価入力済み' : '最終評価を入力'}
							</Button>
							{!isFinalDone &&
								<Typography variant='body1' style={{fontSize : 12}} color = 'primary'> 会社としての評価を入力ください</Typography>
							}
						</Grid>
					}
				</Grid>
			</Grid>
		);
	}

	function renderScheduled () {
	
		if (props.interviews) {
			return;
		}

		let scheduled = props.scheduledInterview;
		if (!scheduled) {
			return;
		}

		let contents = [];
		let authInfo = authStore.getState();
		let picProfile = authInfo.picProfile;

		for  (let i = 0; i < scheduled.length; i++) {
			const interviewReviews = scheduled[i].interview_reviews;
			const latestReview = interviewReviews[0];
			const otherReview = interviewReviews.slice(1);
			const isLastReview = interviewReviews.length-1;
			const hasTempReviews = interviewReviews?.find(rev => rev.pic_id === picProfile.id) ? true : false;
			const isFinalDone = interviewReviews?.find(rev => rev.is_final) ? true : false;
			contents.push (
				<Grid className = 'mt-12' key = {i}>
					{renderReviews(scheduled, i, latestReview, 0, false, hasTempReviews, isFinalDone)}
					<Collapse in = {viewMore} unmountOnExit>
						{otherReview.map ((reviews, reviewIndex) => (
							renderReviews(scheduled, i, reviews, reviewIndex+1, reviewIndex+1 === isLastReview, hasTempReviews, isFinalDone)
						))}
					</Collapse>
				</Grid>
			);
		}

		return contents;
	}

	function renderInterviews () {

		if (props.scheduledInterview) {
			return;
		}

		let interviews = props.interviews;

		if (!interviews) {
			return;
		}

		let contents = [];
		contents.push (
			<Grid container spacing = {2} className = 'mt-12' key = 'header'>
				<Grid item xs = {3} sm = {2} md = {2} lg = {2} xl = {2}>
					<Typography variant = 'subtitle2'> 開始時間 </Typography>
				</Grid>
				<Grid item xs = {3} m = {3} md = {3} lg = {3} xl = {3}>
					<Typography variant = 'subtitle2'> 職種名 </Typography>
				</Grid>
				<Grid item xs = {3} sm = {4} md = {3} lg = {4} xl = {4}>
					<Typography variant = 'subtitle2' > 注 </Typography>
				</Grid>
				<Grid item xs = {2} sm = {2} md = {2} lg = {2} xl = {2}>
					<Typography variant = 'subtitle2'> ステップ </Typography>
				</Grid>
				<Grid item xs = {1} sm = {1} md = {1} lg = {1} xl = {1}>
					<Typography variant = 'subtitle2'> 結果 </Typography>
				</Grid>
			</Grid>
		);

		for (let i = 0; i < interviews.length; i++) {
			contents.push (
				<Grid className = 'mt-12' key = {i}>
					<Grid container spacing = {2} alignItems = 'center'>
						<Grid item xs = {3} sm = {2} md = {2} lg = {2} xl = {2}>
							<Typography variant='h5' style={{ color: '#278bfa' }} className='mb-16'> {interviews[i].step}</Typography>
							<Typography variant = 'body2' style = {{color : '#278bfa'}}> {interviews[i].start_time ? moment(interviews[i].start_time).format ('ll') : "_"} </Typography>
							<Typography variant = 'subtitle1' style = {{fontSize : '24px'}}> {interviews[i].start_time ? moment(interviews[i].start_time).format ('HH:mm'): "_"} </Typography>
						</Grid>
						<Grid item xs = {3} m = {3} md = {3} lg = {3} xl = {3}>
							<Typography variant = 'body2' style = {{color : '#e64021'}}> {interviews[i].job_name} </Typography>
						</Grid>
						<Grid item xs = {3} sm = {4} md = {3} lg = {4} xl = {4}>
							<Typography variant = 'body2' style = {{fontSize : '14px', maxHeight : '150px', overflow : 'auto'}}> {interviews[i].note || 'N/A'} </Typography>
						</Grid>
						<Grid item xs = {2} sm = {2} md = {2} lg = {2} xl = {2}>
							<Typography variant = 'body2'> {interviews[i].step} </Typography>
						</Grid>
						<Grid item xs = {1} sm = {1} md = {1} lg = {1} xl = {1}>
							<Typography variant = 'body2'> {interviews[i].result || 'N/A'} </Typography>
						</Grid>
					</Grid>
					<Divider className = 'mt-12'/>
				</Grid>
			);
		}

		return contents;
	}

	function renderVideo () {

		let vurl = applicant.pr_video;
		let vid = vurl.split ('/');

		vid = vid[vid.length - 1];
		let vsrc = applicant.pr_video.includes ('vimeo.com') ? `https://player.vimeo.com/video/${vid}?controls=1&autoplay=0&loop=0` : applicant.pr_video;
		return (
			<div className = 'embed-container'>
				<iframe
					src={vsrc}
					frameBorder="0"
					allow = "fullscreen"
					allowFullScreen
				>
				</iframe>
			</div>
		);
	}

	function showJobName () {
		
		if (!props.showJobName) {
			return;
		}
		return (
			<Typography variant = 'body2' style = {{color : '#e64021', textAlign : 'center'}}> {props.showJobName} </Typography>
		);
	}

	function renderCartButton () {
		
		if (props.interviews || props.scheduledInterview) {
			return;
		}
		
		if (props.jobName) {
			return (
				<Typography variant = 'h6' style = {{color : '#e64021'}} align = 'center' className = 'mt-8'> {props.jobName} </Typography>
			);
		}

		if (props.showStars) {
			return renderStars ();
		}

		if (!props.jobId) {
			// If there is no job id, means there is no job, in this case we wont allow user to add to cart or remove from cart
			return;
		}

		if (props.removeButton) {
			return (
				<Button disabled = {loading} color = 'primary' className = 'mt-12' fullWidth onClick = {() => removeFromCart (applicant.id, applicant.job_id)}>
					{loading ? <Loader color = 'primary'/> : '検討リストから削除'}
				</Button>
			);
		}

		let isAdded = props.cart.find (a => a.applicant_id === applicant.id && a.job_id === props.jobId);
	
		if (isAdded) {
			return (
				<Button
					disabled = {loading}
					color = 'primary'
					className = 'mt-12'
					fullWidth
					onClick = {() => removeFromCart (isAdded.applicant_id, isAdded.job_id)}
				>
					{loading ? <Loader color = 'primary'/> : '検討リストから削除'}
				</Button>
			);
		}

		return (
			<Button disabled = {loading} variant = 'outlined' color = 'primary' className = 'mt-12 designed-button-outlined' fullWidth onClick = {addToCart}>
				<StarOutlineIcon className = 'mr-12'/>
				{loading ? <Loader color = 'primary'/> : '検討リスト追加'}
			</Button>
		);
	}

	function renderAgeGender (customFields) {
		if (applicant.age && customFields['性別']) {
			return (
				<Grid container alignItems = 'center'>
					<User color = 'black' size = {16} style = {styles.icons}/>
					<Typography variant = 'body2'> <strong> 性別・年齢: </strong> {customFields['性別']}・{applicant.age} </Typography>
				</Grid>
			);
		}
		if (applicant.age) {
			return (
				<Grid container alignItems = 'center'>
					<User color = 'black' size = {16} style = {styles.icons}/>
					<Typography variant = 'body2'> <strong> 年齢: </strong> {applicant.age} </Typography>
				</Grid>
			);
		}
		if (customFields['性別']) {
			return (
				<Grid container alignItems = 'center'>
					<User color = 'black' size = {16} style = {styles.icons}/>
					<Typography variant = 'body2'> <strong> 性別:</strong> {customFields['性別']} </Typography>
				</Grid>
			);
		}
	}

	function renderNationality () {
		if (applicant.country) {
			return (
				<Grid container alignItems = 'center'>
					<World  style = {styles.icons} color = 'black' size = {16} />
					<Typography variant = 'body2'> <strong> 国籍・地域:</strong> {applicant.country} </Typography>
				</Grid>
			);
		}
	}

	function renderBachelor () {
		let masterUni = applicant.universities?.find(uni => uni.education_background === 'Master');
		let bachelorUni = applicant.universities?.find(uni => uni.education_background === 'Bachelor');
		let __uni = masterUni || bachelorUni;
		if (!__uni) {
			return;
		}
		return (
			<Grid container alignItems = 'center'>
				<Disc  style = {styles.icons} color = 'black' size = {16} />
				<Typography variant = 'body2'> <strong> 大学・学部:</strong> {__uni.university_name}・{__uni.faculty} </Typography>
			</Grid>
		);
	}

	function renderJapaneseLevel (customFields) {
		if (customFields['日本語力']) {
			return (
				<Grid container alignItems = 'center'>
					<Notebook  style = {styles.icons} color = 'black' size = {16} />
					<Typography variant = 'body2'> <strong> 日本語:</strong> {customFields["日本語力"]} </Typography>
				</Grid>
			);
		}
		if (customFields["JLPT"]) {
			return (
				<Grid container alignItems = 'center'>
					<Notebook  style = {styles.icons} color = 'black' size = {16} />
					<Typography variant = 'body2'> <strong> JLPT:</strong> {customFields["JLPT"]} </Typography>
				</Grid>
			);
		}
	}

	function renderSkills (customFields) {

		if (Array.isArray (customFields["IT Skills"])) {
			return(
				<Grid container alignItems = 'center'>
					<DeviceDesktop  style = {styles.icons} color = 'black' size = {16} />
					<Typography variant = 'body2'> <strong> ITスキル: </strong> {customFields["IT Skills"].join (', ')} </Typography>
				</Grid>
			);
		}
		if (customFields["IT Skills"]) {
			return(
				<Grid container alignItems = 'center'>
					<DeviceDesktop  style = {styles.icons} color = 'black' size = {16} />
					<Typography variant = 'body2'> <strong> ITスキル: </strong> {customFields["IT Skills"]} </Typography>
				</Grid>
			);
		}
	}

	function renderBlackLandingTag () {
		let _custom_fields = applicant.custom_fields;
		if (!_custom_fields) {
			return;
		}
		if (page === 'LANDING' || page === 'CHECKOUT') {
			let array = [];
			if (Array.isArray(_custom_fields["特徴"]) && _custom_fields["特徴"].length) {
				_custom_fields["特徴"].map ((item, index) => (
					array.push (
						<span key = {index} className = 'black-landing-tag'>
							{item}
						</span>
					)
				));
			}
			return array;
		}
	}

	function renderBlueLandingTag () {
		let _custom_fields = applicant.custom_fields;
		if (!_custom_fields) {
			return;
		}
		if (page === 'LANDING' || page === 'CHECKOUT') {
			let array = [];
			if (!_custom_fields["入社タイミング"]) {
				return;
			}
			if (Array.isArray(_custom_fields["入社タイミング"]) && _custom_fields["入社タイミング"].length) {
				_custom_fields["入社タイミング"].map ((item, index) => (
					array.push (
						<span key = {index} className = 'blue-landing-tag'>
							{item}
						</span>
					)
				));
			}
			return array;
		}
	}

	function renderPersonalInfo () {
		if (page === 'LANDING' || page === 'CHECKOUT') {
			let _custom_fields = applicant.custom_fields;
			if (!_custom_fields) {
				return;
			}

			return (
				<Grid>
					<Grid container>
						<Typography variant = 'body2' style = {{color : '#666'}}>{applicant.id || applicant.applicant_id} </Typography>
						{applicant.recommended ? <span style = {{...styles.recommended, display : 'none'}} className = 'ml-24'> Recommended </span> : null}
						{applicant.is_high_spec ? <span style = {styles.highSpec}> HIGH-SPEC </span> : null}
					</Grid>
					<Typography variant = 'h4' style = {styles.name} component = {Navlink} to = {`${path.profile}/${applicant.id || applicant.applicant_id}`} target = "_blank">
						{_custom_fields["ASIA to JAPAN コメント"] && _custom_fields["ASIA to JAPAN コメント"].slice (0, 60)}...
					</Typography>
					{renderAgeGender (_custom_fields)}
					{renderNationality ()}
					{renderBachelor (_custom_fields)}
					{renderJapaneseLevel (_custom_fields)}
					{renderSkills (_custom_fields)}
				</Grid>
			);
		}
		
		if (props.hidePersonal) {
			return (
				<Grid>
					<Typography variant = 'h4' style = {styles.name} component = {Navlink} to = {`${path.profile}/${applicant.id || applicant.applicant_id}?page=${props.page}`}>
						{applicant.id || applicant.applicant_id}
					</Typography>
					{applicant.recommended ? <span style = {{...styles.recommended, display : 'none'}} className = 'ml-24'> Recommended </span> : null}
					<Typography variant = 'h6' style = {styles.aid}> </Typography>
				</Grid>
			);
		}

		return (
			<Grid>
				<Grid container alignItems = 'center'>
					<Grid item xs = {6}>
						<Typography variant = 'h4' style = {styles.name} component = {Navlink} to = {`${path.profile}/${applicant.id || applicant.applicant_id}?interview=true`}>
							{applicant.name || applicant.applicant_name}
						</Typography>
					</Grid>
					{applicant.attendance ?
						<Grid item xs = {6} container>
							<Grid>
								<Typography> 生徒参加時間 / </Typography>
								<Typography variant = 'h5' align = 'right'> {applicant.attendance.participated_hours} / </Typography>
							</Grid>
							<Grid>
								<Typography className = 'ml-4'> 実施時間 / </Typography>
								<Typography variant = 'h5' align = 'right'> {applicant.attendance.conducted_time} / </Typography>
							</Grid>
							<Grid>
								<Typography className = 'ml-4'> 総授業時間 </Typography>
								<Typography variant = 'h5' align = 'left' className = 'ml-4'> {applicant.attendance.total_time}</Typography>
							</Grid>
						</Grid>
						: null}
					<Grid item xs = {6}>
						{applicant.event_name ? <Typography variant = 'body2' style = {styles.event}> {applicant.event_name}</Typography> : null}
					</Grid>
				</Grid>
				{applicant.recommended ? <span style = {{...styles.recommended, display : 'none'}} className = 'ml-24'> Recommended </span> : null}
				<Grid container  alignItems = 'center'>
					<Grid item xs = {6}>
						<Typography variant = 'h6' style = {styles.aid}> {applicant.id || applicant.applicant_id}</Typography>
					</Grid>
					<Grid item xs = {6}>
					</Grid>
				</Grid>
				<Typography variant = 'body2' style = {styles.email}> {applicant.email || applicant.applicant_email}</Typography>
			</Grid>
		);
	}

	function renderJobReviewFields () {
		if (page !== "LANDING" && page !== "INTERVIEW") {
			return;
		}

		let _jobsReview = page === 'LANDING' ? applicant.job_review_fields || [] : applicant.selection_fields || [];
		let reviews = [];

		for (let i = 0; i < _jobsReview.length; i++) {
			let fieldsArray = [];
			let fieldsObj = Object.keys (_jobsReview[i].fields);
			for (let j = 0; j < fieldsObj.length; j++) {
				if (!_jobsReview[i].fields[fieldsObj[j]]) {
					continue;
				}
				fieldsArray.push (
					<Grid container key = {j} spacing = {2}>
						<Grid item xs = {6} sm = {4} md = {4} lg = {4} xl = {4}>
							<Typography variant = 'body2'> <strong>{fieldsObj[j]} : </strong></Typography>
						</Grid>
						<Grid item xs = {6} sm = {8} md = {8} lg = {8} xl = {8}>
							<Typography variant = 'body2'> {_jobsReview[i].fields[fieldsObj[j]]} </Typography>
						</Grid>
					</Grid>
				);
			}
			if (!fieldsArray.length) {
				continue;
			}
			reviews.push (
				<Grid key = {`job-review-${i}`} className = 'mb-12'>
					<Typography variant = 'h6' align = 'center'> {_jobsReview[i].job} </Typography>
					<Grid>
						{fieldsArray}
					</Grid>
				</Grid>
			);
		}

		if (!reviews.length) {
			return;
		}

		return (
			<Grid>
				<Divider className = 'mt-8 mb-8'/>
				{reviews}
				<Divider className = 'mt-8 mb-8'/>
			</Grid>
		);
	}

	return (
		<Paper elevation = {2} className = 'p-16 mt-24'>
			{(page === 'LANDING' || page === 'CHECKOUT') && applicant.recommendation_memo &&
				<Alert icon={false} severity="info" className='mb-16'>
					<Typography color = 'primary' variant='h4' style = {{fontSize : 14}}> 推薦ポイント </Typography>
					{applicant.recommendation_memo}
				</Alert>
			}
			<Grid container spacing = {3}>
				<Grid item xs = {12} sm = {6} md = {6} lg = {7} xl = {8}>
					{renderPersonalInfo ()}
					{ page === 'LANDING' || page === 'CHECKOUT' ? null :<Grid>
						{applicant.age ? <Typography variant = 'body2'> <strong> 年齢 :  </strong>{applicant.age} </Typography> : null}
						{applicant.bachelor_university ? <Typography variant = 'body2'> <strong> 大学 :  </strong>{applicant.bachelor_university} </Typography> : null}
						{applicant.master_university ? <Typography variant = 'body2'> <strong> マスター大学 : </strong> {applicant.master_university}</Typography> : null}
					</Grid>}
					<Grid>
						{renderCustomFields()}
						{renderJobReviewFields ()}
						{ page === 'JCLASS' && applicant.has_jclass ? <Button variant='contained' color='primary' style={{width : '50%'}} className='mt-24' onClick={() => props.onDownload(applicant)}> 日本語学習レポート </Button> : null}
					</Grid>
				</Grid>
				<Grid item xs = {12} sm = {6} md = {6} lg = {5} xl = {4}>
					{applicant.pr_video ?
						renderVideo ()
						: null
					}
					{showJobName ()}
					<Grid container justify = 'center'>
						<Grid item xs = {6} sm = {8}>
							{renderCartButton()}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing = {3} alignItems = 'flex-end'>
				<Grid item xs = {12} sm = {8} md = {8} lg = {9} xl = {10}>
					<div>
						{renderBlackLandingTag ()}
					</div>
					<div>
						{renderBlueLandingTag  ()}
					</div>
				</Grid>
				<Grid item xs = {12} sm = {4} md = {4} lg = {3} xl = {2}>
				</Grid>
			</Grid>
			{!props.scheduledInterview || !props.interviews ? null : <Divider/>}
			{renderInterviews ()}
			{renderScheduled ()}
		</Paper>
	);
}

export default ApplicantCard;
