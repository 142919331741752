import React, {useEffect, useState } from 'react';
import ReactHtmlParser               from 'react-html-parser';
import { serialize }                 from 'object-to-formdata';
import { useSnackbar }               from 'notistack';
import Grid                          from '@material-ui/core/Grid';
import Button                        from '@material-ui/core/Button';
import Paper                         from '@material-ui/core/Paper';
import Link                          from '@material-ui/core/Link';
import Divider                       from '@material-ui/core/Divider';
import Typography                    from '@material-ui/core/Typography';
import TextField                     from '@material-ui/core/TextField';
import RadioGroup                    from '@material-ui/core/RadioGroup';
import Radio                         from '@material-ui/core/Radio';
import FormControlLabel              from '@material-ui/core/FormControlLabel';
import FormControl                   from '@material-ui/core/FormControl';
import FormGroup                     from '@material-ui/core/FormGroup';
import Checkbox                      from '@material-ui/core/Checkbox';
import Collapse                      from '@material-ui/core/Collapse';
            
import AutoSelect                    from 'components/atoms/AutoSelect';
import Loader                        from 'components/atoms/Loader';
import utility                       from 'common/utility';
import { styles }                    from './style';
import apis                          from 'common/apis';
import Log                           from 'common/log';
import {errorMsg}                    from 'common/errors';

const log = Log ('ApplicantNotice', 'info');

function ApplicantNotice ({applicantData, applicantId, applicantName, applicantDownloadDoc}) {

	const [editable, setEditable]                   = useState (false);
	const [customFields, setCustomFields]           = useState ([]);
	const [editFields, setEditFields]               = useState ({});
	const [documentFields, setDocumentFields]       = useState ([]);
	const [loading, setLoading]                     = useState (false);
	const [errors, setErrors]                       = useState ({});
	const [downloadDoc, setDownloadDoc]             = useState (null);
	const [appData, setAppData]                     = useState ([]);
	const [editLoading, setEditLoading]             = useState (false);
	const [viewMore, setViewMore]                   = useState (false);

	const notify = useSnackbar ().enqueueSnackbar;

	useEffect(() => {
		setCustomFields (applicantData);
		setAppData (applicantData);
	},[applicantData]);

	useEffect (() => {
		setDownloadDoc (applicantDownloadDoc);
	},[applicantDownloadDoc]);

	const handleViewMore = () => {
		setViewMore (!viewMore);
	};

	const handleEdit = async () => {
		if (editable) {
			setEditable (!editable);
			return;
		}
		setEditLoading (true);
		let editableFields;
		try {
			editableFields = await apis.getOnboardingEditProfile (applicantId);
			log.info ({applicantEditable : editableFields}, 'applicant edit field get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting applicant edit data');
			if (err.response && err.response.status === 401) {
				notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			setEditLoading(false);
			return;
		}
		setEditable (!editable);
		let customisedFields = getCustomisedFields(editableFields.custom_fields);
		let _documentFields = getCustomizedDocumentFields (editableFields.document_fields);
		setEditFields (customisedFields.customFields);
		setErrors (customisedFields.customErrors);
		setDocumentFields (_documentFields);
		setEditLoading(false);
	};

	const getDropDownValue = (value) => {
		if (!value) {
			return null;
		}
		if (value['label']) {
			return value.label;
		}
		return value;
	};

	const getCustomizedDocumentFields = (document_fields) => {
		document_fields = document_fields.map (item => {
			return {
				...item,
				value : null,
				errors : false,
			};
		});
		return document_fields;
	};

	const downloadFile = async (ev, document_id) => {
		let data = {
			applicant : {
				document_id : document_id,
				applicant_id : applicantId,
			}
		};
		let link;
		try {
			link =  await apis.getDownloadLink (data);
		}
		catch (err) {
			log.error ({err}, 'error in downloading file');
			if (err.response && err.response.status === 401) {
				notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (!link.download_url) {
			notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		return window.open (link.download_url);
	};

	const onSave = async () => {
			
		let customFields   = editFields;
		let __customFields  = Object.keys(customFields);
		let customFieldsData = {};
		let _errors = {};

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType   = customFields[__customFields[i]].input_type;
			let __isMandatory = customFields[__customFields[i]]['is_mandatory?'];
			let __value = __inputType === 'drop_down' ? getDropDownValue(customFields[__customFields[i]].value) : customFields[__customFields[i]].value;
			if (Array.isArray (__value) && !__isMandatory) {
				let _options = customFields[__customFields[i]].options || [];
				__value.filter (v => _options.includes (v));
				customFieldsData[__customFields[i]] = __value;
				continue;
			}
			if (!__isMandatory) {
				customFieldsData[__customFields[i]] = __value;
				continue;
			}
			switch (__inputType) {
				case 'text' :
					if (!utility.validateTextField (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'number' :
					if (!utility.validateNumber (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'date' :
					if (!__value) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'email' :
					if (!utility.validateEmail (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'phone_number' :
					if (!utility.validatePhoneNumber (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'url' :
					if (!utility.validateUrl (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'location' :
					if (!utility.validateTextField (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'drop_down':
					if (!__value) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'text_area':
					if (!utility.validateTextField (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'currency' :
					if (!utility.validateCurrency (__value)) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'check_box' : {
					if (!__value.length) {
						_errors[__customFields[i]] = true;
						break;
					}
					if (typeof __value === 'string') {
						__value = __value.split (',');
						__value = __value.map(v => v.trim());
					}
					let _options = customFields[__customFields[i]].options || [];
					__value.filter (v => _options.includes (v));
					customFieldsData[__customFields[i]] = __value;
					break;
				}
				case 'radio_button':
					if (!__value) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				default :
					if (!__value) {
						_errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
			}
		}

		/*Return if there is any error field*/

		if (Object.keys (_errors).length) {
			notify ('Please fill all the mandatory fields.', {variant : 'error'});
			setErrors ({
				...errors,
				..._errors
			});
			return;
		}

		let documentError = false;
		let document_attributes = {};
		for (let i = 0; i < documentFields.length; i++) {
			if (documentFields[i].errors) {
				documentError = true;
				continue;
			}
			if (documentFields[i]['is_mandatory?'] && !documentFields[i].value) {
				documentFields[i].errors = true;
				documentError = true;
				continue;
			}
			let upload = documentFields[i].value;
			if (!upload) {
				continue;
			}
			document_attributes = {
				...document_attributes,
				[documentFields[i].document_name_id] : {
					document_name_id : documentFields[i].document_name_id,
					upload : upload,
				}
			};
		}

		if (documentError) {
			notify ('Please upload all the required documents.',{variant : 'error'});
			setDocumentFields ([...documentFields]);
			return;
		}

		let jsonData = {
			applicant : {
				id : applicantId,
				custom_fields : {
					...customFieldsData,
				},
				documents_attributes: {
					...document_attributes,
				}
			}
		};

		let data = serialize ({
			...jsonData,
		}, {allowEmptyArrays: true,});

		setLoading (true);

		let result;

		try {
			result = await apis.saveProfile (data, applicantId);
			log.info ({applicantNotice : result}, 'applicant onboarding data save ok');
		}
		catch (err) {
			log.error ({err}, 'error updating applicant onboarding data');
			if (err.response &&  err.response.status === 401) {
				notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			notify ('Something went wrong, please try again', {variant : 'error'});
			setLoading (false);
			return;
		}
		if (result.errors) {
			notify (result.errors, {variant : 'error'});
			setLoading (false);
			return;
		}
		setLoading (false);
		setEditable (false);
		setCustomFields (result.custom_fields);
		setAppData (result.custom_fields);
		notify (result.message, {variant : 'success'});
	};

	const getCustomisedFields = (custom_fields) => {

		let __customFields = Object.keys(custom_fields);
		let customFields = {};
		let customErrors = {};

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType = custom_fields[__customFields[i]].input_type;
			let __options   = custom_fields[__customFields[i]].options;
			let value;
			for (let j = 0; j < appData.length; j++) {
				if (appData[j].fields[__customFields[i]]) {
					value = appData[j].fields[__customFields[i]];
				}
			}
			customFields[__customFields[i]] = {
				...custom_fields[__customFields[i]],
				value   : value ? value : __inputType === 'check_box' ? [] : '',
				options : __options.length ? __options.map ((item, index) => {
					return {
						name : item,
						id   : index
					};
				})  : __options
			};
			customErrors[__customFields[i]] = false;
		}
		return {customFields, customErrors};
	};

	const handleCustomFields = (event) => {
		const { name, value } = event.currentTarget;
		setEditFields ({
			...editFields,
			[name] : {
				...editFields[name],
				value: value
			}
		});
		setErrors ({
			...errors,
			[name] : false
		});
	};

	const handleCustomCheckbox = (event) => {

		const { name, checked, value } = event.target;

		setEditFields ({
			...editFields,
			[name] : {
				...editFields[name],
				value: checked ? [...editFields[name].value, value] : editFields[name].value.filter(v => v !== value)
			}
		});
		setErrors ({
			...errors,
			[name] : false
		});
	};

	const handleCustomSelect = (name, value) => {
		setEditFields ({
			...editFields,
			[name] : {
				...editFields[name],
				value : value,
			}
		});
		setErrors ({
			...errors,
			[name] : false
		});
	};

	const handleDocChange = (event, id) => {

		let files    = event.target.files[0];
		if (!files) {
			return;
		}
		let __documents = documentFields;
		let __documentIndex = __documents.findIndex (d => d.document_name_id === id);
		if (__documentIndex < 0) {
			return;
		}
		let __document = __documents[__documentIndex];

		const fileSize = event.target.files[0].size;
		if (fileSize > 10*1024*1024) { // files size should not be greater than 10 mb
			__document = {
				...__document,
				errors : true,
			};
			__documents[__documentIndex] = __document;
			setDocumentFields (__documents);
			return;
		}
		__document = {
			...__document,
			value : files,
			errors : false,
		};
		__documents[__documentIndex] = __document;
		setDocumentFields (__documents);
	};

	const renderRadio = () => {
		return (
			<Radio color = 'primary' size = 'small'/>
		);
	};

	const renderEditable = (field, value) => {

		let editCustomFields = editFields;
		let customField = Object.prototype.hasOwnProperty.call(editCustomFields, field) ? editCustomFields[field] : value;
		if (!customField) {
			return;
		}
		let inputType = customField.input_type;
			
		switch (inputType) {

			case 'text' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'number' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'number'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'date' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'date'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'email' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'email'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'phone_number' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'url' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'url'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'location' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'drop_down': {
				let value;
				if (!customField.value || !customField.value['label']) {
					let selected = customField.options.find (item => item.name === customField.value);
					value = selected ? {label : selected.name, value : selected.id} : null;
				}
				else {
					value = customField.value;
				}
				return (
					<AutoSelect
						value       = {value}
						error       = {errors[field]}
						options     = {utility.autoSelectOptions (customField.options, 'name', 'id')}
						name        = {field}
						searchable  = {true}
						placeholder = {`Select ${field}`}
						onChange    = {handleCustomSelect}
					/>
				);
			}
			case 'text_area':
				return (
					<TextField
						value       = {customField.value.replaceAll ("<br>", "")}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						multiline   = {true}
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'currency' :
				return (
					<TextField
						value       = {customField.value}
						error       = {errors[field]}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						onChange    = {handleCustomFields}
						placeholder = {`Enter ${field}`}
						fullWidth
					/>
				);
			case 'check_box' :
				return (
					<FormGroup row = {true}>
						{customField.options.map ((item, index) => (
							<FormControlLabel
								label   = {item.name}
								key     = {index}
								control = {<Checkbox color = 'primary' checked = {customField.value.includes(item.name) ? true : false} name = {field} value = {item.name} onChange ={handleCustomCheckbox}/>}/>
						))}
					</FormGroup>
				);
			case 'radio_button':
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {customField.value} onChange = {handleCustomFields} row = {true} name = {field}>
							{customField.options.map ((item, index) => (
								<FormControlLabel value = {item.name}  label = {item.name} control = {renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);
			default :
				return;
		}
	};

	const renderValues = (field, value) => {
		if (editable) {
			return renderEditable (field, value);
		}

		if (Array.isArray (value) && value.length) {
			return (
				<Typography variant = 'body1'> {value.join (', ')} </Typography>
			);
		}

		if (Array.isArray (value) && !value.length) {
			return (
				<Typography variant = 'body1'> '空欄</Typography>
			);
		}

		return (
			<Typography variant = 'body1'> {value ? ReactHtmlParser(value) : '空欄'} </Typography>
		);
	};

	const renderDownloadDoc = () => {
		if (!downloadDoc || editable) {
			return;
		}
 
		let __downloadDocs = downloadDoc;
		let allDocs = Object.keys (__downloadDocs);
		if (!allDocs.length) {
			return;
		}
 
		let buttons = allDocs.map ((doc, index) => (
			<Grid container key = {index} spacing = {4} alignItems = 'center'>
				<Grid item xs = {5} sm = {4} md = {3} lg = {3} xl = {3}>
					<Typography variant = 'subtitle2'> {doc} </Typography>
				</Grid>
				<Grid item xs = {7} sm = {8} md = {9} lg = {9} xl = {9} className = 'pl-8'>
					<Button color = 'primary' style = {{padding : 0}} onClick = {(ev) => downloadFile (ev, __downloadDocs[doc].document_id)}> Download </Button>
				</Grid>
			</Grid>
		));
		return (
			<Grid>
				<Typography variant = 'subtitle1' className = 'mt-16 mb-16' align = 'center' style = {styles.categoryNameApplicant}> Onboarding Documents </Typography>
				{buttons}
			</Grid>
		);
	};

	const renderDocumentFields = () => {
		if (!editable) {
			return;
		}
		let __documents = [];
		documentFields.map (documents => (
			__documents.push (
				<Grid container spacing = {4} alignItems = 'center' key = {documents.document_name_id}>
					<Grid container item xs = {5} sm = {4} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'subtitle2'> {documents.document_name} </Typography>
					</Grid>
					<Grid container item xs = {7} sm = {8} md = {9} lg = {9} xl = {9} className = 'pl-8' alignItems = 'center'>
						<TextField
							type       = 'file'
							name       = {documents.document_name}
							error      = {documents.errors}
							onChange   = {(ev) => handleDocChange(ev, documents.document_name_id)}
							helperText = {documents.errors ?
								"Docmuent size  is greater than 10MB"
								:
								"Upload docx, pdf, pptx, png, jpeg or txt of max size 10MB"}
							inputProps = {{
								accept : 'jpeg,.png,.pdf,.txt,.pptx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							}}
						/>
					</Grid>
				</Grid>
			)
		));
		return (
			<Grid>
				<Typography variant = 'subtitle1' className = 'mt-16 mb-16' align = 'center' style = {styles.categoryNameApplicant}> Onboarding Documents </Typography>
				{__documents}
			</Grid>
		);
	};

	return (
		<Paper className = 'p-24' variant = 'outlined'>
			<Grid container alignItems = 'center' style = {{minHeight : '66px'}}>
				<Grid item xs = {3}>
					<Typography variant = 'h6'> {applicantId} </Typography>
				</Grid>
				<Grid item xs = {6}>
					<Typography variant = 'h5' align = 'center' color = 'primary'> {applicantName} </Typography>
				</Grid>
				<Grid item xs = {3} container justify = 'flex-end'>
					{viewMore ?
						<Button variant = 'contained' color = 'secondary' onClick = {handleEdit}>
							{editLoading ? <Loader color = 'primary' /> : editable ? 'キャンセル' : '編集'}
						</Button>
						:
						null
					}
					
				</Grid>
			</Grid>
			<Grid>
				<Link  style = {{cursor : 'pointer'}} color='primary' onClick={handleViewMore}> {!viewMore ? '詳細を表示' : '非表示'}</Link>
			</Grid>
			<Collapse in = {viewMore} timeout = 'auto'>
				{customFields.map ((category, index) => (
					<Grid key = {index}>
						<Typography variant = 'subtitle1' className = 'mt-16 mb-16' align = 'center' style = {styles.categoryNameApplicant}> {category.category_name} </Typography>
						{Object.keys (category.fields).map ((p, i) => (
							<Grid container spacing = {4} key = {i} alignItems = 'center'>
								<Grid container item xs = {5} sm = {4} md = {3} lg = {3} xl = {3}>
									<Typography variant = 'subtitle2'> {p} </Typography>
								</Grid>
								<Grid item xs = {7} sm = {8} md = {9} lg = {9} xl = {9} className = 'pl-8'>
									{renderValues (p, customFields[index].fields[p])}
									{!editable ? <Divider className = 'mt-8'/> : null}
								</Grid>
							</Grid>
						))}
					</Grid>
				))}
				{renderDownloadDoc ()}
				{renderDocumentFields ()}
				{editable ?
					<Grid container justify = 'center' className = 'mt-24'>
						<Button
							variant  = 'contained'
							color    = 'primary'
							onClick  = {onSave}
							disabled = {loading}
							style    = {{width : '200px'}}
						>
							{loading ? <Loader color = 'secondary'/> : '保存'}
						</Button>
					</Grid>
					: null}
			</Collapse>
		</Paper>
	);
}

export default ApplicantNotice;